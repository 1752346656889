import React from "react"
import work1 from "../../asserts/work01.jpg"
import work2 from "../../asserts/work02.jpg"
import work21 from "../../asserts/work21.jpg"
import work22 from "../../asserts/work22.jpg"

const WhyUs = () => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <p
          name="anchor"
          style={{ position: "absolute", top: "0px" }}
          id="whyUs"
        ></p>
      </div>
      <div className="text-black bg-white-default flex md:flex-row flex-col w-full justify-center space-x-0 sm:space-x-32 my-40">
        {/* Left sec */}
        <div className="md:w-1/2 w-full relative h-[400px]">
          <img
            loading="lazy"
            src={work1}
            alt=""
            className="absolute w-72 md:left-40 left-[40%] -top-32 p-1 border-4"
          />
          <img
            loading="lazy"
            src={work2}
            alt=""
            className="absolute w-72 md:left-2 md:-top-4 top-32 p-1 border-4"
          />

          <img
            loading="lazy"
            src={work22}
            alt=""
            className="absolute w-72 md:left-72 left-[40%] md:-bottom-14 -bottom-[200px] p-1 border-4"
          />
          <img
            loading="lazy"
            src={work21}
            alt=""
            className="absolute w-72 md:left-12 -bottom-[450px] md:-bottom-28 p-1 border-4"
          />

          {/* <div className="absolute w-40 h-40 bg-blue-light text-white-default bottom-6 md:right-5 right-5 border-4 flex flex-col justify-center items-center text-center">
            <span className="font-bold text-4xl">25+</span>
            <span className="mt-2">
              Years of <br /> experience
            </span>
          </div> */}
        </div>

        {/* right sec */}
        <div className="md:w-2/6 space-y-12 flex flex-col justify-center items-center text-center md:mt-0 mt-[480px]">
          <div className="capitalize text-yellow-default flex items-center w-full justify-center md:justify-start">
            <hr className="w-10 mt-1 mr-2 border-2 rounded-full border-yellow-default" />
            <span>why choose us</span>
          </div>
          <div className="md:space-y-3 flex justify-center flex-col">
            <p>
              "Happiness Guaranteed!": If you're not fully satisfied, we'll fix
              it. Still not delighted? We'll refund your money. Our amazing team
              is committed to taking care of people. As a locally owned Edmonton
              business since 2010, we deliver fantastic cleaning results backed
              with excellent staff, and time-tested processes. Check our Google
              Reviews for customer feedback! 😊
            </p>
            <p>
              we deliver fantastic cleaning results backed by excellent staff,
              and time-tested processes. Check our Google Reviews for customer
              feedback!
            </p>
          </div>
          <a href="#reviews" className="text-white-default group md:mt-20 uppercase border h-12 pl-6 text-left items-center justify-center bg-blue-light flex hover:shadow-md hover:shadow-blue-dark overflow-hidden relative">
            <span className="w-48">More About Us</span>
            <span className="bg-yellow-default h-full flex items-center justify-center w-14 absolute skew-x-12 -right-2 border-l-2">
              <svg
                className="w-6 h-6 group-hover:-translate-x-4 duration-700"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </span>
          </a>
        </div>
      </div>

      {/* bottom sec */}
      <div className="w-full md:h-[160px] h-[360px] relative overflow-hidden bg-no-repeat bg-cover bg-fixed bg-parallax">
        <div className="bg-blue-light bg-opacity-80 h-full grid grid-cols-2 md:flex justify-center items-center">
          <div className="flex flex-col items-center text-center px-20">
            <svg
              className="w-11 h-11 mb-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
              />
            </svg>

            <h2 className="font-medium text-lg">#</h2>
            <h3>Highly Professional Staff</h3>
          </div>
          <span className="hidden md:block h-10 w-1 bg-white-default rounded" />

          <div className="flex flex-col items-center text-center px-20">
            <svg
              className="w-11 h-11 mb-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z"
              />
            </svg>
            <h2 className="font-medium text-lg">4.5</h2>
            <h3>Star Rating</h3>
          </div>
          <span className="hidden md:block h-10 w-1 bg-white-default rounded" />

          <div className="flex flex-col items-center text-center px-20">
            <svg
              className="w-11 h-11 mb-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
              />
            </svg>
            <h2 className="font-medium text-lg">100%</h2>
            <h3>Satisfaction</h3>
          </div>
          <span className="hidden md:block h-10 w-1 bg-white-default rounded" />

          <div className="flex flex-col items-center text-center px-20">
            <svg
              className="w-11 h-11 mb-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z"
              />
            </svg>
            <h2 className="font-medium text-lg">$***</h2>
            <h3>Best Service</h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default WhyUs
