const updates = [
  {
    id: 1,
    name: "Please don’t share your bank",
    type: "important",
    text: "This is to inform all our valuable customers that if someone calls you and tells you that he or she is calling from our office please cut the call and block, it’s a spam. Please don’t share your bank details with anyone. Be safe be alert! Thanks",
    img:"https://firebasestorage.googleapis.com/v0/b/millwoodsfurwebsite.appspot.com/o/img1.jpg?alt=media&token=01854919-869f-478d-90ed-5dd68cb3709c",
  },
  {
    id: 2,
    name: "Be safe be alert!",
    type: "important",
    text: "This is to inform all our valuable customers that if someone calls you and ask for credit card details for billing that’s not us, it’s a spam. Please don’t share your bank details with anyone. Be safe be alert! Thanks",
    img: "https://firebasestorage.googleapis.com/v0/b/millwoodsfurwebsite.appspot.com/o/img2.jpg?alt=media&token=94f6a8c4-1734-46be-9fcc-edaae477ee88",
  },
]

export default updates
