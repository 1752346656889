import React, { useState } from "react"
import updates from "./updateData"
import _ from "lodash"

const UpdateSection = () => {
  const [Selected, setselected] = useState(1)

  return (
    <React.Fragment>
      <div
        className="bg-blue-light flex flex-col items-center md:py-24 py-12 mx-auto"
        id="updates"
      >
        <div className="flex space-x-3">
          <hr className="w-14 mt-3 border rounded-full border-white-default" />
          <span className="font-medium">Latest Update</span>
          <hr className="w-14 mt-3 border rounded-full border-white-default" />
        </div>
        <div className="my-2">
          <span className="text-4xl">See Our Latest News</span>
        </div>

        <div className="w-[95%] md:h-[500px] flex flex-col md:flex-row justify-center md:divide-x-2 mt-10">
          {/* right sec */}
          <div className="flex-1 flex justify-center items-center">
            <div className="md:w-[420px] flex flex-col items-center rounded-xl cursor-pointer hover:shadow-lg hover:shadow-yellow-default overflow-hidden bg-white-default text-black">
              <img
                src={updates[Selected].img}
                alt=""
                className="w-full h-[250px] object-cover"
              />
              <div className="px-3 py-10">
                <h1 className="font-medium text-xl capitalize">
                  {updates[Selected].name}
                </h1>
                <p className="my-2 text-xs bg-gray-500 w-fit px-1 uppercase text-white-default">
                  {updates[Selected].type}
                </p>
                <p>{updates[Selected].text}</p>
              </div>
            </div>
          </div>

          {/* left sec */}
          <div className="flex-1 flex flex-col md:items-center md:h-[500px] py-10 space-y-5">
            {updates.map((data, key) => {
              return (
                <div
                  key={key}
                  className="bg-white-default text-black md:w-[540px] h-[130px] rounded-xl overflow-hidden flex items-center cursor-pointer hover:shadow-lg hover:shadow-yellow-default"
                  onClick={() => {
                    setselected(key)
                  }}
                >
                  <img
                    src={data.img}
                    alt=""
                    className="md:w-48 w-28 object-cover"
                  />
                  <div className="p-3">
                    <h1 className="font-medium capitalize">{data.name}</h1>
                    <p className="my-1 text-xs bg-gray-500 w-fit px-1 uppercase text-white-default">
                      {data.type}
                    </p>
                    <p>
                      {_.truncate(data.text, {
                        length: 80,
                      })}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UpdateSection
