import React from "react"
import styles from "./Footer.module.css"
import logo from "../../../asserts/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faYoutube,
  faSquareInstagram,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons"
import moment from "moment"

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.left}>
            <div className={styles.head}>
              <div className={styles.logoContainer}>
                <img src={logo} alt="logo" style={{ color: "#e8bb36" }}></img>
              </div>
              <h3>Millwoods Furnace Cleaning Ltd</h3>
              <p>Air Duct Cleaning Service in Edmonton</p>
            </div>

            <div className={styles.icons}>
              <FontAwesomeIcon
                icon={faFacebook}
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/profile.php?id=100051782624103"
                  )
                }}
              />
              <FontAwesomeIcon
                icon={faSquareInstagram}
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/millwoods_furnace_cleaning_ltd"
                  )
                }}
              />
              {/* <FontAwesomeIcon icon={faSquareXTwitter} onClick={()=>{window.open("")}}/>
              <FontAwesomeIcon icon={faYoutube} onClick={()=>{window.open("")}}/> */}
            </div>
          </div>

          <div className={styles.right}>
            <div>
              <h4 style={{ fontWeight: "bold" }}>Company</h4>

              <ul
                className={styles.companyList}
                style={{ listStyleType: "square", listStylePosition: "inside" }}
              >
                <a href="#home">
                  <li>Home</li>
                </a>
                <a href="#whyUs">
                  <li>Why Us</li>
                </a>
                <a href="#services">
                  <li>Services</li>
                </a>
                <a href="#aboutUs">
                  <li>About Us</li>
                </a>
                <a href="#contactUs">
                  <li>Contact Us</li>
                </a>
              </ul>
            </div>
            <div>
              <div>
                <h4 style={{ fontWeight: "bold" }}>Office Address</h4>
                <p>
                  3307 9 Street Northwest Edmonton,
                  <br /> AB T6T 1A4 Canada
                </p>
              </div>
              <div>
                <h4 style={{ fontWeight: "bold" }}>Email Us</h4>
                <p>millwoodsfur@gmail.com</p>
                <h4 style={{ fontWeight: "bold" }}>Phone Number</h4>
                <p>+1-780-399-7475</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className="text-center">
            <b>&copy; Copyright MFC {moment().format("YYYY")}.</b> All right
            reserrved.<br/> Powered By <a className="underline font-medium" href="https://linktr.ee/r.media">R Enterprise</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
