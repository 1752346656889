import React from "react"
import HVAC_Cleaning from "../../asserts/HVAC_Cleaning.jpg"

const OurServices = () => {
  return (
    <>
      <div
        className="bg-blue-light flex flex-col items-center py-24 mx-auto"
        id="services"
      >
        <div className="flex space-x-3">
          <hr className="w-14 mt-3 border rounded-full border-white-default" />
          <span className="font-medium">Our Services</span>
          <hr className="w-14 mt-3 border rounded-full border-white-default" />
        </div>
        <div className="my-2">
          <span className="text-4xl">Our Awesome Services</span>
        </div>

        {/* service titles */}
        <div className="my-16 bg-white-default bg-opacity-25 flex flex-col md:flex-row md:divide-x-2 divide-y-2 md:divide-y-0 divide-solid">
          <div className="h-28 px-12 flex flex-col justify-center items-center">
            <svg
              className="w-12 h-12 fill-white-default"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path
                  d="M780 3460 c-19 -19 -20 -33 -20 -426 0 -260 4 -412 10 -425 8 -13 21
-19 46 -19 46 0 54 23 54 161 l0 109 1695 0 1695 0 0 -425 0 -425 -261 0
c-160 0 -268 -4 -280 -10 -17 -10 -19 -22 -19 -130 l0 -120 -180 0 -180 0 0
179 c0 99 -3 186 -6 195 -6 14 -83 16 -773 16 -583 0 -770 -3 -779 -12 -8 -8
-12 -66 -12 -195 l0 -183 -175 0 -175 0 0 111 c0 61 -5 119 -10 130 -10 18
-24 19 -280 19 l-270 0 0 244 c0 209 -2 247 -16 260 -18 19 -38 20 -65 6 -18
-10 -19 -26 -19 -423 0 -242 4 -418 10 -427 8 -13 218 -15 1771 -18 1569 -2
1765 0 1786 13 l23 15 0 890 c0 869 0 890 -19 900 -13 6 -171 10 -446 10 -275
0 -433 -4 -446 -10 -13 -8 -19 -21 -19 -46 0 -28 5 -36 26 -44 15 -6 188 -10
420 -10 l394 0 0 -210 0 -210 -1700 0 -1700 0 0 210 0 210 1219 0 c768 0 1229
4 1245 10 21 8 26 16 26 44 0 25 -6 38 -19 46 -13 7 -429 10 -1275 10 -1243 0
-1256 0 -1276 -20z m2470 -1565 l0 -145 -690 0 -690 0 0 145 0 145 690 0 690
0 0 -145z m-1930 -65 l0 -80 -230 0 -230 0 0 80 0 80 230 0 230 0 0 -80z
m2940 0 l0 -80 -230 0 -230 0 0 80 0 80 230 0 230 0 0 -80z"
                />
                <path
                  d="M1077 3292 c-14 -15 -17 -40 -17 -128 0 -72 4 -114 12 -122 17 -17
469 -17 486 0 19 19 16 232 -4 252 -13 14 -49 16 -238 16 -203 0 -224 -2 -239
-18z m393 -122 l0 -40 -155 0 -155 0 0 40 0 40 155 0 155 0 0 -40z"
                />
                <path
                  d="M1612 2678 c-19 -19 -15 -66 7 -78 13 -7 325 -10 941 -10 616 0 928
3 941 10 22 12 26 59 7 78 -17 17 -1879 17 -1896 0z"
                />
                <path
                  d="M1612 2518 c-19 -19 -15 -66 7 -78 13 -7 325 -10 941 -10 616 0 928
3 941 10 22 12 26 59 7 78 -17 17 -1879 17 -1896 0z"
                />
              </g>
            </svg>
            <span>HVAC Cleaning</span>
          </div>

          <div className="h-28 px-12 flex flex-col justify-center items-center">
            <svg
              className="w-12 h-12 fill-white-default"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path
                  d="M815 4854 c-36 -38 -191 -259 -268 -381 -87 -138 -192 -350 -212
-429 -72 -289 100 -582 394 -668 88 -26 236 -22 328 9 181 61 314 201 368 389
24 81 17 227 -15 316 -51 145 -182 374 -353 614 -120 169 -127 176 -177 176
-31 0 -47 -6 -65 -26z m142 -307 c148 -212 278 -447 304 -550 17 -71 7 -173
-25 -243 l-28 -59 -19 60 c-20 67 -63 142 -146 256 -92 129 -114 149 -163 149
-49 0 -71 -20 -163 -149 -83 -114 -126 -189 -146 -256 l-19 -60 -28 59 c-32
70 -42 172 -25 243 26 103 156 338 304 550 40 57 74 103 77 103 3 0 37 -46 77
-103z m-23 -679 c97 -137 115 -201 76 -266 -76 -125 -280 -75 -280 69 0 20 12
58 26 85 34 63 112 174 124 174 5 0 29 -28 54 -62z"
                />
                <path
                  d="M340 4780 c-13 -13 -20 -33 -20 -60 0 -53 27 -80 80 -80 53 0 80 27
80 80 0 53 -27 80 -80 80 -27 0 -47 -7 -60 -20z"
                />
                <path
                  d="M1300 4780 c-13 -13 -20 -33 -20 -60 0 -53 27 -80 80 -80 53 0 80 27
80 80 0 27 -7 47 -20 60 -13 13 -33 20 -60 20 -27 0 -47 -7 -60 -20z"
                />
                <path
                  d="M2180 4540 c-13 -13 -20 -33 -20 -60 0 -53 27 -80 80 -80 53 0 80 27
80 80 0 27 -7 47 -20 60 -13 13 -33 20 -60 20 -27 0 -47 -7 -60 -20z"
                />
                <path
                  d="M2500 4540 c-13 -13 -20 -33 -20 -60 0 -27 7 -47 20 -60 19 -19 33
-20 220 -20 187 0 201 1 220 20 13 13 20 33 20 60 0 27 -7 47 -20 60 -19 19
-33 20 -220 20 -187 0 -201 -1 -220 -20z"
                />
                <path
                  d="M2296 4225 c-154 -25 -345 -85 -467 -146 -59 -30 -69 -42 -69 -90 0
-43 15 -65 50 -78 34 -11 49 -8 175 47 277 120 633 147 937 71 168 -42 333
-116 488 -217 113 -75 327 -289 402 -402 111 -171 193 -365 235 -560 26 -123
26 -459 -1 -580 -26 -119 -62 -230 -108 -333 -43 -95 -44 -121 -8 -157 20 -20
71 -27 105 -14 37 15 136 270 177 458 20 88 22 131 22 336 0 206 -2 248 -22
337 -50 230 -150 460 -280 643 -86 121 -271 306 -392 392 -181 129 -413 230
-638 279 -128 28 -470 36 -606 14z"
                />
                <path
                  d="M2321 3904 c-115 -19 -273 -74 -392 -135 -375 -193 -640 -554 -713
-974 -21 -118 -21 -352 0 -470 99 -565 544 -1010 1109 -1109 118 -21 352 -21
470 0 421 74 784 341 978 721 103 202 147 389 147 623 0 234 -44 421 -147 623
-195 382 -567 654 -985 722 -115 19 -353 18 -467 -1z m532 -187 c222 -59 380
-150 547 -317 166 -167 257 -322 317 -547 25 -90 27 -113 27 -293 0 -180 -2
-203 -27 -293 -33 -125 -81 -235 -149 -345 -72 -118 -252 -298 -370 -370 -399
-246 -877 -246 -1276 0 -118 72 -298 252 -370 370 -68 110 -116 220 -149 345
-25 90 -27 113 -27 293 0 180 2 203 27 293 58 214 161 393 316 548 173 174
363 276 609 330 132 28 390 22 525 -14z"
                />
                <path
                  d="M2422 3581 c-177 -66 -286 -253 -253 -437 7 -37 35 -108 76 -189 36
-71 65 -140 65 -153 0 -37 -59 -131 -94 -149 -22 -12 -71 -18 -176 -23 -172
-8 -226 -26 -313 -109 -203 -192 -149 -526 103 -645 60 -29 73 -31 170 -31 93
0 112 3 162 27 79 37 140 97 205 202 85 139 116 166 193 166 77 0 108 -27 193
-166 65 -105 126 -165 205 -202 50 -24 69 -27 162 -27 97 0 110 2 170 31 252
119 306 453 103 645 -87 83 -141 101 -313 109 -105 5 -154 11 -176 23 -35 18
-94 112 -94 149 0 13 29 82 65 153 76 151 91 214 76 312 -20 130 -104 244
-220 300 -76 37 -228 44 -309 14z m226 -167 c98 -40 158 -157 132 -255 -6 -23
-36 -85 -65 -138 -30 -53 -56 -107 -60 -121 -6 -24 -8 -25 -95 -25 -87 0 -89
1 -95 25 -4 14 -30 68 -60 121 -29 53 -59 115 -65 138 -26 97 34 213 130 255
46 20 131 20 178 0z m-10 -724 c130 -79 74 -280 -78 -280 -85 0 -150 65 -150
150 0 85 65 150 150 150 27 0 58 -8 78 -20z m-380 -248 c6 -21 25 -56 41 -77
l29 -39 -27 -36 c-15 -19 -51 -74 -80 -123 -75 -125 -134 -163 -241 -155 -96
7 -175 74 -201 171 -25 95 26 202 120 250 48 24 66 27 155 28 56 0 119 4 141
8 22 5 43 9 46 10 3 0 11 -16 17 -37z m787 19 c123 -6 145 -10 185 -31 55 -31
106 -104 115 -163 19 -125 -78 -246 -205 -255 -107 -8 -166 30 -241 155 -29
49 -65 104 -80 123 l-27 36 29 39 c16 21 35 56 41 77 9 29 15 37 27 32 9 -3
79 -9 156 -13z"
                />
                <path
                  d="M1001 3181 c-23 -23 -62 -142 -93 -286 -19 -87 -22 -130 -22 -335 -1
-207 2 -247 22 -337 148 -674 642 -1168 1316 -1315 88 -20 131 -22 336 -22
205 0 248 2 335 22 180 39 423 130 453 169 14 20 16 77 2 102 -5 10 -22 24
-38 30 -32 13 -47 9 -177 -47 -94 -41 -219 -76 -343 -97 -128 -22 -414 -16
-532 10 -166 36 -378 122 -504 203 -182 117 -362 297 -478 478 -50 78 -119
226 -153 328 -60 180 -70 248 -70 476 0 222 8 280 65 464 31 103 29 145 -10
166 -31 17 -88 12 -109 -9z"
                />
                <path
                  d="M3948 1509 c-22 -12 -38 -70 -38 -136 0 -29 -3 -53 -7 -53 -5 0 -30
14 -58 30 -58 34 -116 39 -143 12 -9 -9 -54 -83 -100 -163 -111 -197 -111
-220 7 -284 28 -16 51 -31 51 -35 0 -4 -23 -19 -51 -35 -118 -64 -118 -87 -7
-284 46 -80 91 -154 100 -163 27 -27 85 -22 143 12 28 16 53 30 58 30 4 0 7
-24 7 -52 0 -69 16 -125 40 -138 11 -6 102 -10 214 -10 242 0 236 -3 236 126
l0 86 64 -36 c72 -40 125 -47 154 -18 9 9 54 83 100 163 111 197 111 220 -7
284 -28 16 -51 31 -51 35 0 4 23 19 51 35 118 64 118 87 7 284 -46 80 -91 154
-100 163 -29 29 -82 22 -154 -18 l-64 -36 0 86 c0 130 7 126 -237 126 -113 0
-205 -5 -215 -11z m282 -241 c0 -202 47 -233 210 -138 47 27 88 46 91 42 4 -4
21 -33 38 -64 l32 -57 -94 -55 c-98 -58 -107 -68 -107 -116 0 -48 9 -58 107
-116 l94 -55 -32 -57 c-17 -31 -34 -60 -38 -64 -3 -4 -44 15 -91 42 -163 95
-210 64 -210 -137 l0 -93 -75 0 -75 0 0 120 c0 107 -2 122 -20 140 -34 34 -84
26 -180 -30 -47 -27 -88 -46 -91 -42 -4 4 -21 33 -38 64 l-32 57 94 55 c98 58
107 68 107 116 0 48 -9 58 -107 116 l-94 55 32 57 c17 31 34 60 38 64 3 4 44
-15 91 -42 96 -56 146 -64 180 -30 18 18 20 33 20 140 l0 120 75 0 75 0 0 -92z"
                />
                <path
                  d="M2180 700 c-13 -13 -20 -33 -20 -60 0 -27 7 -47 20 -60 19 -19 33
-20 220 -20 187 0 201 1 220 20 13 13 20 33 20 60 0 27 -7 47 -20 60 -19 19
-33 20 -220 20 -187 0 -201 -1 -220 -20z"
                />
                <path
                  d="M2820 700 c-13 -13 -20 -33 -20 -60 0 -53 27 -80 80 -80 53 0 80 27
80 80 0 53 -27 80 -80 80 -27 0 -47 -7 -60 -20z"
                />
              </g>
            </svg>
            <span>Furnace & Duct Cleaning</span>
          </div>

          <div className="h-28 px-12 flex flex-col justify-center items-center">
            <svg
              className="w-12 h-12 fill-white-default"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path
                  d="M678 4484 c-33 -18 -58 -62 -58 -104 l0 -30 -62 0 c-255 -1 -467
-156 -540 -393 -29 -97 -23 -264 14 -354 31 -75 71 -134 129 -191 100 -99 203
-142 357 -149 l102 -6 0 -72 0 -72 -91 -8 c-163 -12 -274 -60 -374 -160 -106
-106 -155 -227 -155 -385 0 -158 49 -279 155 -385 100 -100 211 -148 374 -160
l91 -8 0 -72 0 -72 -102 -6 c-154 -7 -257 -50 -357 -149 -58 -57 -98 -116
-129 -191 -37 -90 -43 -257 -14 -354 73 -237 285 -392 540 -393 l62 0 0 -30
c0 -17 7 -44 15 -60 27 -52 57 -60 226 -60 l151 0 34 34 c22 22 35 47 40 74
l6 40 119 4 c132 3 146 9 156 70 4 30 1 41 -20 62 -25 25 -31 26 -142 26
l-115 0 0 540 0 540 258 0 257 0 60 -29 c69 -32 137 -101 166 -166 10 -24 23
-81 28 -127 10 -101 27 -143 80 -201 52 -57 116 -84 224 -96 104 -12 166 -41
226 -106 67 -72 84 -122 89 -247 l4 -108 -439 0 -440 0 -21 -23 c-30 -32 -29
-81 3 -112 l24 -25 1209 0 1209 0 7 -41 c5 -28 18 -53 40 -75 l34 -34 151 0
c169 0 199 8 226 60 8 16 15 43 15 60 l0 30 105 0 c115 0 148 10 184 56 19 24
21 40 21 176 0 222 -14 238 -200 238 l-110 0 0 74 0 73 95 7 c310 22 525 242
525 540 0 161 -48 282 -156 390 -106 105 -229 156 -381 156 l-83 0 0 80 0 80
81 0 c45 0 109 7 143 16 176 45 335 202 380 379 21 78 21 226 0 296 -71 242
-288 398 -556 399 l-48 0 0 75 0 75 110 0 c186 0 200 16 200 238 0 136 -2 152
-21 176 -36 46 -69 56 -184 56 l-105 0 0 30 c0 17 -7 44 -15 60 -27 52 -57 60
-226 60 l-151 0 -34 -34 c-22 -22 -35 -47 -40 -75 l-7 -41 -782 0 c-776 0
-783 0 -809 -21 -35 -27 -36 -86 -3 -117 20 -19 35 -22 115 -22 l92 0 0 -49
c0 -27 4 -61 10 -75 l10 -26 -320 0 -320 0 0 75 0 75 73 0 c48 0 80 5 94 15
44 31 37 111 -13 134 -18 8 -182 11 -553 11 l-528 0 -7 41 c-5 28 -18 53 -40
75 l-34 34 -153 0 c-117 -1 -161 -4 -181 -16z m252 -1924 l0 -1780 -75 0 -75
0 0 1780 0 1780 75 0 75 0 0 -1780z m3410 0 l0 -1780 -75 0 -75 0 0 1430 c0
1417 0 1430 -20 1450 -26 26 -80 26 -111 1 -22 -18 -24 -28 -27 -130 l-4 -111
-267 0 c-215 0 -278 3 -322 16 -124 36 -235 128 -290 240 -26 53 -33 80 -39
169 -8 115 -20 143 -73 175 -23 15 -50 20 -101 20 -64 0 -70 2 -102 34 -28 28
-34 42 -34 75 l0 41 615 0 615 0 0 -131 0 -131 30 -30 c26 -25 36 -29 63 -24
64 12 67 25 67 257 l0 209 75 0 75 0 0 -1780z m-3720 1556 l0 -73 -72 -5
c-153 -10 -238 -96 -238 -241 0 -53 5 -71 33 -117 44 -75 111 -110 208 -110
l69 0 0 -76 0 -76 -87 4 c-70 3 -100 9 -148 31 -76 35 -148 105 -188 182 -30
56 -32 67 -32 165 0 93 3 112 26 160 36 76 97 141 167 178 71 38 117 50 200
51 l62 1 0 -74z m770 -1 l0 -75 -150 0 -150 0 0 75 0 75 150 0 150 0 0 -75z
m470 0 l0 -75 -155 0 -155 0 0 75 0 75 155 0 155 0 0 -75z m2790 0 l0 -75 -75
0 -75 0 0 75 0 75 75 0 75 0 0 -75z m-4030 -310 l0 -75 -52 0 c-64 0 -98 26
-98 76 0 46 38 74 101 74 l49 0 0 -75z m620 0 l0 -75 -75 0 -75 0 0 75 0 75
75 0 75 0 0 -75z m460 0 l0 -75 -150 0 -150 0 0 75 0 75 150 0 150 0 0 -75z
m470 0 l0 -75 -155 0 -155 0 0 75 0 75 155 0 155 0 0 -75z m780 16 c0 -33 3
-66 6 -75 6 -14 -26 -16 -310 -16 l-316 0 0 75 0 75 310 0 310 0 0 -59z
m-1560 -326 l0 -75 -150 0 -150 0 0 75 0 75 150 0 150 0 0 -75z m470 0 l0 -75
-155 0 -155 0 0 75 0 75 155 0 155 0 0 -75z m620 0 l0 -75 -230 0 -230 0 0 75
0 75 230 0 230 0 0 -75z m555 48 c10 -16 36 -49 57 -75 l40 -48 -246 0 -246 0
0 75 0 75 189 0 189 0 17 -27z m1678 -3 c109 -41 200 -137 233 -246 33 -112 9
-253 -60 -341 -76 -98 -168 -145 -308 -158 l-78 -7 0 81 0 81 71 0 c90 0 156
32 202 100 26 37 32 57 35 112 5 86 -10 131 -63 184 -48 48 -117 74 -197 74
l-48 0 0 76 0 77 78 -6 c42 -3 103 -15 135 -27z m-3473 -355 l0 -75 -75 0 -75
0 0 75 0 75 75 0 75 0 0 -75z m460 0 l0 -75 -150 0 -150 0 0 75 0 75 150 0
150 0 0 -75z m470 0 l0 -75 -155 0 -155 0 0 75 0 75 155 0 155 0 0 -75z m620
0 l0 -75 -230 0 -230 0 0 75 0 75 230 0 230 0 0 -75z m470 0 l0 -75 -155 0
-155 0 0 75 0 75 155 0 155 0 0 -75z m460 0 l0 -75 -150 0 -150 0 0 75 0 75
150 0 150 0 0 -75z m310 0 l0 -75 -75 0 -75 0 0 75 0 75 75 0 75 0 0 -75z
m602 45 c24 -29 23 -75 -2 -100 -15 -15 -33 -20 -75 -20 l-55 0 0 76 0 76 57
-4 c43 -4 61 -10 75 -28z m-4012 -359 l0 -79 -83 -3 c-71 -3 -90 -8 -125 -32
-23 -15 -54 -46 -69 -69 -25 -37 -28 -50 -28 -128 0 -78 3 -91 28 -128 15 -23
46 -54 69 -69 35 -24 54 -29 125 -32 l83 -3 0 -80 0 -81 -81 7 c-142 12 -246
69 -317 176 -49 73 -66 139 -60 240 12 208 178 353 411 359 l47 1 0 -79z m770
-1 l0 -80 -150 0 -150 0 0 80 0 80 150 0 150 0 0 -80z m470 0 l0 -80 -155 0
-155 0 0 80 0 80 155 0 155 0 0 -80z m620 0 l0 -80 -230 0 -230 0 0 80 0 80
230 0 230 0 0 -80z m620 0 l0 -80 -230 0 -230 0 0 80 0 80 230 0 230 0 0 -80z
m470 0 l0 -80 -155 0 -155 0 0 80 0 80 155 0 155 0 0 -80z m460 0 l0 -80 -150
0 -150 0 0 80 0 80 150 0 150 0 0 -80z m-3410 -310 l0 -80 -35 0 c-54 0 -103
24 -111 54 -16 64 26 105 109 106 l37 0 0 -80z m620 0 l0 -80 -75 0 -75 0 0
80 0 80 75 0 75 0 0 -80z m460 0 l0 -80 -150 0 -150 0 0 80 0 80 150 0 150 0
0 -80z m470 0 l0 -80 -155 0 -155 0 0 80 0 80 155 0 155 0 0 -80z m620 0 l0
-80 -230 0 -230 0 0 80 0 80 230 0 230 0 0 -80z m470 0 l0 -80 -155 0 -155 0
0 80 0 80 155 0 155 0 0 -80z m460 0 l0 -80 -150 0 -150 0 0 80 0 80 150 0
150 0 0 -80z m310 0 l0 -80 -75 0 -75 0 0 80 0 80 75 0 75 0 0 -80z m-2640
-310 l0 -80 -150 0 -150 0 0 80 0 80 150 0 150 0 0 -80z m470 0 l0 -80 -155 0
-155 0 0 80 0 80 155 0 155 0 0 -80z m620 0 l0 -80 -230 0 -230 0 0 80 0 80
230 0 230 0 0 -80z m620 0 l0 -80 -230 0 -230 0 0 80 0 80 230 0 230 0 0 -80z
m470 0 l0 -80 -155 0 -155 0 0 80 0 80 155 0 155 0 0 -80z m460 0 l0 -80 -150
0 -150 0 0 80 0 80 150 0 150 0 0 -80z m679 49 c148 -51 251 -198 251 -357 0
-217 -154 -373 -382 -389 l-78 -6 0 77 0 76 48 0 c80 0 149 26 197 74 53 53
68 98 63 186 -5 86 -36 137 -111 180 -45 26 -62 30 -124 30 l-73 0 0 81 0 81
78 -7 c42 -4 101 -16 131 -26z m-2539 -364 l0 -75 -90 0 -90 0 -21 43 c-12 23
-32 57 -46 75 l-24 32 135 0 136 0 0 -75z m620 0 l0 -75 -230 0 -230 0 0 75 0
75 230 0 230 0 0 -75z m470 0 l0 -75 -155 0 -155 0 0 75 0 75 155 0 155 0 0
-75z m460 0 l0 -75 -150 0 -150 0 0 75 0 75 150 0 150 0 0 -75z m310 0 l0 -75
-75 0 -75 0 0 75 0 75 75 0 75 0 0 -75z m600 55 c25 -25 26 -71 2 -100 -14
-18 -32 -24 -75 -28 l-57 -4 0 76 0 76 55 0 c42 0 60 -5 75 -20z m-4010 -365
l0 -75 -69 0 c-97 0 -164 -35 -208 -110 -28 -46 -33 -64 -33 -117 0 -145 85
-231 238 -241 l72 -5 0 -75 0 -75 -86 6 c-155 9 -279 91 -343 227 -23 48 -26
67 -26 160 0 96 3 110 29 160 72 140 196 218 349 219 l77 1 0 -75z m1860 0 l0
-75 -172 0 c-145 0 -179 3 -208 18 -40 20 -80 74 -80 108 l0 24 230 0 230 0 0
-75z m620 0 l0 -75 -230 0 -230 0 0 75 0 75 230 0 230 0 0 -75z m470 0 l0 -75
-155 0 -155 0 0 75 0 75 155 0 155 0 0 -75z m460 0 l0 -75 -150 0 -150 0 0 75
0 75 150 0 150 0 0 -75z m-3410 -310 l0 -75 -49 0 c-62 0 -101 28 -101 73 0
50 33 77 97 77 l53 0 0 -75z m2170 0 l0 -75 -90 0 -90 0 -21 43 c-12 23 -32
57 -46 75 l-24 32 135 0 136 0 0 -75z m470 0 l0 -75 -155 0 -155 0 0 75 0 75
155 0 155 0 0 -75z m460 0 l0 -75 -150 0 -150 0 0 75 0 75 150 0 150 0 0 -75z
m310 0 l0 -75 -75 0 -75 0 0 75 0 75 75 0 75 0 0 -75z m-930 -310 l0 -75 -230
0 -230 0 0 75 0 75 230 0 230 0 0 -75z m470 0 l0 -75 -155 0 -155 0 0 75 0 75
155 0 155 0 0 -75z m460 0 l0 -75 -150 0 -150 0 0 75 0 75 150 0 150 0 0 -75z
m620 0 l0 -75 -75 0 -75 0 0 75 0 75 75 0 75 0 0 -75z"
                />
                <path
                  d="M3454 4029 c-47 -24 -56 -87 -19 -124 50 -51 135 -18 135 52 0 59
-64 98 -116 72z"
                />
                <path
                  d="M3613 3720 c-45 -18 -58 -95 -23 -130 25 -25 80 -26 109 -2 11 9 24
31 27 49 5 27 1 37 -24 63 -31 31 -51 35 -89 20z"
                />
                <path
                  d="M1433 1698 c-47 -23 -53 -103 -10 -133 56 -39 127 -1 127 67 0 60
-59 94 -117 66z"
                />
                <path
                  d="M1283 1390 c-47 -19 -58 -92 -20 -128 27 -25 73 -29 104 -7 44 31 37
111 -13 134 -29 13 -41 13 -71 1z"
                />
                <path
                  d="M1728 1370 c-25 -26 -29 -36 -24 -63 8 -41 39 -67 82 -67 47 0 74 29
74 79 0 31 -6 44 -26 60 -37 29 -70 26 -106 -9z"
                />
              </g>
            </svg>
            <span>Air Conditioning Coil</span>
          </div>

          <div className="h-28 px-12 flex flex-col justify-center items-center">
            <svg
              className="w-12 h-12 fill-white-default"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path
                  d="M832 5097 c-21 -22 -22 -32 -22 -213 l0 -190 -46 -12 c-122 -33 -214
-155 -214 -283 l0 -37 -157 -4 c-175 -5 -212 -14 -272 -71 -18 -18 -44 -54
-57 -82 l-24 -50 0 -700 0 -700 24 -51 c27 -59 90 -116 145 -132 28 -9 412
-12 1470 -12 l1431 0 0 -559 0 -560 -42 -23 c-23 -13 -57 -42 -75 -66 -81
-107 -66 -264 33 -352 79 -69 15 -65 984 -65 970 0 904 -4 985 66 131 116 106
341 -47 420 l-38 19 0 663 c0 701 -4 778 -49 954 -84 329 -251 611 -494 838
-127 119 -249 203 -402 279 -290 144 -506 186 -961 186 l-221 0 -6 60 c-8 72
-42 142 -94 192 -38 37 -113 77 -145 78 -17 0 -18 16 -18 189 0 175 -1 190
-21 215 -16 20 -29 26 -59 26 -92 0 -100 -20 -100 -250 0 -167 -1 -180 -18
-180 -35 0 -122 -52 -161 -96 -46 -53 -71 -118 -71 -186 l0 -48 -425 0 -425 0
0 43 c0 125 -87 243 -204 278 l-45 13 -3 187 c-3 183 -4 188 -27 213 -33 35
-98 36 -129 3z m153 -591 c41 -18 75 -69 75 -113 l0 -33 -165 0 -165 0 0 28
c0 42 31 93 70 114 42 22 136 24 185 4z m1544 -4 c39 -21 71 -73 71 -114 l0
-28 -165 0 -165 0 0 39 c0 45 29 87 74 107 46 20 145 18 185 -4z m-959 -1042
l0 -720 -654 0 -655 0 -20 26 c-21 26 -21 34 -21 694 l0 667 23 20 c12 11 29
23 37 26 8 3 302 6 653 6 l637 1 0 -720z m1540 0 l0 -720 -680 0 -680 0 0 720
0 720 680 0 680 0 0 -720z m325 695 c111 -18 243 -55 354 -100 46 -19 86 -37
89 -39 3 -4 -566 -1002 -585 -1025 -1 -2 -3 262 -3 587 l0 592 28 0 c15 0 68
-7 117 -15z m835 -425 c94 -93 200 -220 200 -239 0 -7 -1003 -589 -1008 -585
-2 1 127 230 288 508 l292 506 61 -43 c34 -23 109 -89 167 -147z m373 -586
c38 -108 64 -223 76 -333 l7 -71 -585 0 c-321 0 -582 3 -578 6 3 4 230 136
504 294 l498 288 22 -42 c12 -23 37 -87 56 -142z m87 -1134 l0 -549 -401 -3
-401 -3 -24 -28 c-30 -35 -31 -85 -4 -120 l21 -26 465 -3 466 -3 24 -28 c30
-36 30 -68 0 -104 l-24 -28 -842 0 -842 0 -24 28 c-30 36 -30 68 0 104 l24 28
212 5 c208 5 212 5 231 29 27 33 24 84 -5 118 -24 28 -24 28 -170 31 l-146 4
0 549 0 549 720 0 720 0 0 -550z"
                />
                <path
                  d="M3310 750 c-29 -29 -32 -52 -28 -220 3 -146 4 -153 27 -171 34 -28
96 -25 126 6 25 24 25 27 25 190 0 163 0 166 -25 190 -31 32 -96 34 -125 5z"
                />
                <path
                  d="M3735 745 l-25 -24 0 -334 c0 -323 1 -335 21 -361 16 -20 29 -26 59
-26 99 0 100 3 100 389 l0 319 -26 31 c-34 41 -92 44 -129 6z"
                />
                <path
                  d="M4159 744 l-24 -26 0 -333 0 -333 24 -26 c33 -35 98 -36 129 -3 22
23 22 28 22 364 l0 342 -26 20 c-38 30 -94 28 -125 -5z"
                />
                <path
                  d="M4590 750 c-29 -29 -32 -52 -28 -220 3 -146 4 -153 27 -171 34 -28
96 -25 126 6 25 24 25 27 25 190 0 163 0 166 -25 190 -31 32 -96 34 -125 5z"
                />
              </g>
            </svg>
            <span>Vent Cleaning</span>
          </div>

          <div className="h-28 px-12 flex flex-col justify-center items-center">
            <svg
              className="w-12 h-12 fill-white-default"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                <path
                  d="M82 4633 c-18 -9 -43 -30 -55 -47 l-22 -31 -3 -843 c-3 -936 -5 -901
64 -1032 68 -131 189 -235 334 -288 l75 -27 1247 -3 1246 -2 -35 -53 c-69
-103 -127 -235 -165 -377 -28 -107 -36 -362 -15 -485 72 -412 352 -755 742
-908 107 -42 242 -73 279 -63 39 10 63 64 47 104 -14 34 -31 42 -151 72 -289
72 -549 289 -675 564 -65 139 -87 235 -92 396 -4 119 -1 162 15 240 44 212
130 369 286 526 206 205 442 304 726 304 288 0 522 -99 730 -309 153 -155 242
-318 284 -523 21 -101 21 -295 0 -396 -84 -410 -394 -725 -799 -814 -49 -10
-98 -26 -107 -35 -26 -23 -24 -87 3 -114 19 -19 29 -21 83 -16 132 13 327 87
461 176 265 175 452 451 514 758 25 123 28 329 6 455 -30 173 -115 368 -222
508 -30 39 -53 73 -51 74 95 59 179 150 228 246 58 114 60 138 60 604 0 413
-1 427 -20 446 -27 27 -83 27 -110 0 -19 -19 -20 -34 -20 -387 -1 -418 -7
-487 -50 -579 -35 -73 -116 -161 -175 -192 l-40 -20 -75 55 c-94 69 -250 145
-357 175 l-82 23 -3 285 c-3 264 -4 288 -23 319 -11 18 -36 42 -55 52 -33 18
-91 19 -1550 19 -1459 0 -1517 -1 -1550 -19 -70 -37 -75 -55 -78 -337 -5 -365
13 -475 94 -581 l25 -33 -245 0 c-143 0 -267 5 -298 11 -187 40 -325 194 -349
391 -6 50 -8 376 -7 823 l3 740 120 5 c67 3 129 10 141 17 40 23 46 76 13 117
-20 26 -20 26 -167 26 -115 0 -154 -4 -180 -17z m3948 -1383 l0 -80 -1470 0
-1470 0 0 80 0 80 1470 0 1470 0 0 -80z m0 -325 l0 -85 -1109 0 c-1068 0
-1110 -1 -1132 -19 -31 -25 -32 -83 -1 -114 l23 -22 756 -3 756 -2 -62 -42
c-34 -23 -82 -59 -106 -80 l-44 -38 -888 2 -888 3 -56 28 c-31 15 -76 49 -100
75 l-44 47 196 5 c175 4 199 7 218 24 28 26 29 88 1 116 -19 19 -33 20 -240
20 l-220 0 0 85 0 85 1470 0 1470 0 0 -85z"
                />
                <path
                  d="M661 4624 c-12 -15 -21 -37 -21 -50 0 -67 36 -84 179 -84 l110 0 6
-47 c17 -138 75 -227 183 -280 l57 -28 1385 0 1385 0 57 28 c108 53 166 142
183 280 l6 47 387 -2 387 -3 3 -247 2 -247 27 -20 c33 -27 63 -27 97 0 l27 21
-3 282 c-3 272 -4 282 -25 312 -50 69 142 64 -2251 64 l-2161 0 -20 -26z
m3364 -176 c-10 -60 -29 -92 -72 -125 l-36 -28 -1357 0 -1357 0 -36 28 c-43
33 -62 65 -72 125 l-7 42 1472 0 1472 0 -7 -42z"
                />
                <path
                  d="M960 3740 c-31 -31 -27 -93 6 -119 24 -19 40 -21 164 -21 157 0 176
7 186 72 5 30 2 41 -19 62 -26 26 -29 26 -172 26 -132 0 -147 -2 -165 -20z"
                />
                <path
                  d="M1502 3737 c-32 -34 -30 -89 4 -116 24 -19 40 -21 166 -21 134 0 140
1 163 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -23 24 -29 25 -168 25 -137 0
-144 -1 -165 -23z"
                />
                <path
                  d="M472 3477 c-18 -7 -48 -25 -68 -41 -107 -85 -96 -273 19 -353 55 -38
164 -45 223 -15 203 104 142 407 -84 419 -31 1 -72 -3 -90 -10z"
                />
                <path
                  d="M4492 3475 c-60 -26 -94 -57 -118 -109 -54 -117 -13 -240 100 -298
58 -30 166 -23 222 15 117 79 127 265 20 354 -63 52 -156 67 -224 38z"
                />
                <path
                  d="M4225 2325 c-104 -22 -104 -22 -130 -170 -20 -118 -19 -137 6 -169
l22 -28 -43 -43 -43 -44 -35 30 c-60 53 -120 63 -184 30 l-37 -18 -31 32 c-31
32 -32 33 -15 65 19 37 14 71 -15 100 -25 25 -77 26 -102 3 -18 -16 -23 -12
-121 85 -96 96 -105 102 -143 102 -51 0 -78 -27 -78 -76 0 -31 12 -47 104
-139 99 -100 103 -105 87 -123 -9 -10 -17 -32 -17 -48 0 -65 60 -100 120 -69
32 17 33 16 65 -15 l33 -32 -19 -32 c-21 -35 -25 -105 -8 -139 6 -12 24 -36
40 -52 l29 -31 -44 -43 -44 -43 -31 23 -31 23 -117 -19 c-65 -10 -128 -22
-140 -28 -26 -11 -38 -41 -53 -121 -23 -132 -6 -176 68 -176 51 0 70 22 83 98
l11 60 50 7 c50 7 50 7 99 -41 50 -49 55 -63 43 -123 -5 -26 -12 -31 -43 -37
-95 -16 -121 -35 -121 -89 0 -38 16 -62 47 -72 38 -13 217 16 239 39 10 10 24
59 35 125 24 137 24 154 -2 187 l-22 28 44 44 43 44 191 -188 c153 -152 198
-191 231 -200 164 -47 305 54 304 219 -1 81 -30 122 -221 314 l-179 181 44 43
44 44 28 -22 c16 -13 41 -21 63 -21 65 1 231 36 248 53 18 18 42 129 43 200 0
57 -24 87 -71 87 -59 0 -77 -23 -94 -122 -3 -21 -9 -38 -13 -38 -4 -1 -26 -5
-49 -9 -41 -8 -43 -7 -92 43 -51 52 -56 66 -45 124 6 28 10 31 68 41 70 12 96
35 96 87 0 71 -56 88 -195 59z m-82 -793 c264 -265 272 -277 227 -322 -45 -45
-57 -37 -324 230 l-251 249 45 46 c24 25 47 45 50 45 3 0 117 -112 253 -248z"
                />
                <path
                  d="M1470 2132 c-17 -13 -25 -35 -31 -75 -5 -41 -20 -79 -53 -134 -53
-89 -71 -161 -63 -255 6 -66 12 -83 86 -222 42 -80 41 -125 -5 -213 -69 -129
-75 -145 -81 -211 -5 -57 -2 -71 15 -92 15 -19 29 -25 61 -25 54 0 72 21 82
90 5 38 21 79 53 133 90 154 90 280 -2 436 -62 107 -68 166 -23 246 63 110 82
158 88 223 5 61 4 67 -20 91 -31 31 -75 34 -107 8z"
                />
                <path
                  d="M1970 2132 c-17 -13 -25 -35 -31 -75 -5 -41 -20 -79 -53 -134 -53
-89 -71 -161 -63 -255 6 -66 12 -83 86 -222 42 -80 41 -125 -5 -213 -69 -129
-75 -145 -81 -211 -5 -57 -2 -71 15 -92 15 -19 29 -25 61 -25 54 0 72 21 82
90 5 38 21 79 53 133 90 154 90 280 -2 436 -62 107 -68 166 -23 246 63 110 82
158 88 223 5 61 4 67 -20 91 -31 31 -75 34 -107 8z"
                />
                <path
                  d="M2470 2132 c-17 -13 -25 -35 -31 -75 -5 -41 -20 -79 -53 -134 -53
-89 -71 -161 -63 -255 6 -66 13 -85 87 -223 43 -82 39 -136 -18 -236 -100
-171 -97 -304 7 -304 54 0 72 21 82 90 5 38 21 79 53 133 90 154 90 280 -2
436 -62 107 -68 166 -23 246 64 112 83 162 89 225 4 58 2 65 -21 89 -31 31
-75 34 -107 8z"
                />
              </g>
            </svg>
            <span>Heating Recovery Ventilator (HRV)</span>
          </div>
        </div>

        {/* Service Details */}
        <div className="flex md:w-[92%] justify-between flex-col md:flex-row">
          <div className="flex-1">
            <div className="p-12 space-y-5">
              <h1 className="text-4xl">
                Comprehensive Cleaning Services for Residential and Commercial
                Spaces
              </h1>
              <p>
                This comprehensive range of cleaning services caters to both
                residential and commercial needs. For homeowners, services
                include furnace cleaning, duct cleaning, air conditioning coil
                cleaning, heating recovery ventilator cleaning, dryer vent
                cleaning, and maintenance of furnace filters. On the commercial
                front, offerings encompass HVAC system cleaning, condo/apartment
                dryer vent cleaning, high dusting, and carpet, furniture, and
                upholstery cleaning. Whether it’s a cozy home or a bustling
                business, these services ensure a clean and healthy environment.
              </p>
            </div>
          </div>
          <div className="flex-1 relative overflow-hidden justify-center items-center flex">
            <img src={HVAC_Cleaning} alt="clean" className="object-cover" />
          </div>
        </div>
      </div>
    </>
  )
}

export default OurServices
