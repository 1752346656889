import React, { useEffect, useState } from "react"
import people from "./ReviewData"
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from "react-icons/fa"
// import "./ReviewCSS.css"

const ReviewSection = () => {
  const [index, setIndex] = useState(0)
  const { id, name, stars, image, text } = people[index]
  const checkNumber = (number) => {
    if (number > people.length - 1) {
      return 0
    }
    if (number < 0) {
      return people.length - 1
    }
    return number
  }

  const nextPerson = () => {
    setIndex((index) => {
      let newIndex = index + 1
      return checkNumber(newIndex)
    })
  }

  const prevPerson = () => {
    setIndex((index) => {
      let newIndex = index - 1
      return checkNumber(newIndex)
    })
  }

  const minIndex = 0
  const maxIndex = people.length - 1

  const getRandomPerson = () => {
    const getRandomIntInclusive = (min, max) => {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1) + min) //The maximum is inclusive and the minimum is inclusive
    }

    let randomIndex = getRandomIntInclusive(minIndex, maxIndex)
    if (randomIndex === index) {
      randomIndex = index + 1
    }
    setIndex(checkNumber(randomIndex))
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://static.elfsight.com/platform/platform.js"
    script.dataset.useServiceCore = true
    script.defer = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className="my-10 md:h- [720px] flex flex-col items-center">
      <div style={{ position: "relative" }}>
        <p
          name="anchor"
          style={{ position: "absolute", top: "-90px" }}
          id="reviews"
        ></p>
      </div>
      {/* <div className="capitalize text-yellow-default flex items-center">
        <hr className="w-10 mt-1 mr-2 border-2 rounded-full border-yellow-default" />
        <span>Reviews</span>
      </div> */}

      <iframe
        title="reviews"
        src="https://b29e638f4870431baa775aafff62f391.elf.site"
        className="w-[120%]"
        height="700"
        frameborder="0"
      ></iframe>

      {/* overview */}
      {/* <div className="bg-blue-light my-3 border-2 border-yellow-default shadow-xl w-72 h-20 rounded-xl p-4 flex justify-between items-center">
        <div>
          <div className="flex">
            <h1 className="text-2xl font-medium">4.5</h1>
            <h2 className="text-xl h-2 mt-1 ml-1">★★★★☆</h2>
          </div>
          <p className="text-sm">Rating on Google</p>
        </div>

        <div className="flex flex-col">
          <h1 className="text font-medium">{people.length}+</h1>
          <h2 className="text-sm">Public Reviews</h2>
        </div>
      </div>
      <article className="py-6 px-2 rounded shadow text-center hover:shadow-lg text-black border-4 md:w-[70%] w-[95%] m-auto">
        <div className="relative w-[150px] h-[150px] rounded-xl m-auto mb-6 before:w-full before:h-full before:absolute before:-top-1 before:-right-2 before:rounded-xl">
          {image ? (
            <img
              src={image}
              alt={name}
              loading="lazy"
              className="w-full h-full object-cover rounded-full relative"
            />
          ) : (
            <svg
              className="w-full h-full object-cover rounded-full relative"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
              >
                <path
                  d="M714 5096 c-65 -30 -120 -85 -150 -150 l-24 -51 0 -905 0 -905 24
-51 c30 -65 85 -120 150 -150 47 -22 64 -24 202 -24 150 0 151 0 178 26 37 37
37 110 1 147 -24 24 -33 25 -153 29 -135 4 -160 11 -184 57 -20 36 -19 1707 1
1743 30 56 -99 53 2071 53 2170 0 2041 3 2071 -53 20 -36 21 -1707 1 -1743
-29 -54 -29 -54 -429 -59 l-373 -5 -172 -172 -172 -172 -177 174 -176 175
-411 0 -412 0 -30 59 c-37 75 -128 173 -204 223 -69 44 -258 108 -321 108 -31
0 -49 -6 -66 -24 -16 -16 -63 -131 -142 -352 -132 -365 -156 -419 -226 -515
-66 -89 -159 -163 -315 -247 l-129 -71 -40 34 c-73 63 -89 65 -502 65 -420 0
-430 -1 -511 -73 -26 -23 -57 -63 -70 -92 l-24 -50 0 -955 0 -955 24 -50 c29
-64 77 -112 141 -141 50 -24 52 -24 440 -24 388 0 390 0 440 24 28 12 66 39
85 59 l35 36 130 -44 c224 -76 208 -75 1089 -75 l781 0 57 24 c103 42 169 110
209 216 26 69 27 178 2 252 l-19 55 53 28 c66 34 127 100 161 173 37 81 42
194 13 277 -12 32 -21 60 -21 62 0 1 16 10 35 19 135 64 218 231 194 388 -19
119 -102 232 -205 277 -26 12 -43 25 -40 32 86 202 9 416 -182 505 l-57 27
-442 3 c-244 1 -443 6 -443 9 0 4 28 90 61 192 41 125 63 208 67 254 l5 67
346 0 345 0 201 -200 201 -200 49 0 48 0 202 200 201 200 345 0 c341 0 344 0
395 24 65 30 120 85 150 150 l24 51 0 905 0 905 -24 51 c-30 65 -85 120 -150
150 l-51 24 -2065 0 -2065 0 -51 -24z m1483 -1917 c120 -57 193 -168 201 -304
4 -79 3 -83 -87 -358 -85 -257 -91 -281 -81 -313 24 -71 5 -68 581 -74 503 -5
518 -6 545 -26 53 -39 69 -71 69 -134 0 -63 -16 -95 -69 -134 -26 -20 -44 -21
-344 -26 -347 -5 -353 -6 -378 -65 -19 -45 -8 -90 28 -120 l30 -25 406 0 c375
0 410 -2 448 -19 124 -56 131 -216 13 -286 -32 -19 -55 -20 -456 -23 l-421 -3
-25 -25 c-36 -36 -43 -76 -20 -122 28 -58 47 -61 394 -61 301 -1 307 -1 340
-24 58 -39 81 -84 77 -145 -5 -56 -25 -91 -72 -126 -26 -20 -44 -21 -360 -26
-364 -5 -356 -4 -382 -67 -17 -41 -8 -85 23 -115 24 -22 31 -23 252 -28 l228
-5 36 -28 c48 -36 67 -75 67 -134 0 -60 -33 -112 -87 -139 -36 -18 -74 -19
-693 -22 -847 -4 -921 2 -1155 93 l-95 37 0 851 0 852 146 79 c233 126 342
217 444 373 57 86 88 160 206 485 101 280 82 259 191 207z m-1695 -1684 l3
-635 28 -27 c39 -40 105 -40 144 0 l28 27 3 635 2 635 120 0 c111 0 121 -2
147 -24 l28 -24 0 -911 0 -911 -28 -27 -27 -28 -345 0 -345 0 -27 28 -28 27 0
911 0 911 28 24 c26 22 36 24 147 24 l120 0 2 -635z"
                />
                <path
                  d="M553 638 c-31 -15 -56 -73 -49 -112 20 -108 182 -108 202 0 8 41 -18
97 -52 113 -30 14 -72 13 -101 -1z"
                />
                <path
                  d="M1534 4529 c-44 -12 -89 -68 -130 -157 -19 -42 -36 -77 -37 -79 -2
-1 -48 -10 -102 -18 -55 -9 -115 -23 -133 -32 -44 -21 -82 -87 -82 -144 0 -57
26 -99 108 -175 l66 -60 -18 -105 c-9 -58 -14 -119 -10 -138 9 -46 70 -106
119 -120 53 -14 95 -3 193 49 l80 43 91 -47 c134 -69 214 -64 274 16 36 49 39
73 20 190 l-17 108 81 83 c86 88 102 123 89 189 -9 45 -46 95 -82 113 -17 8
-75 22 -130 30 -54 9 -99 17 -100 18 -1 1 -22 43 -47 92 -49 98 -74 126 -126
144 -39 13 -63 13 -107 0z m94 -334 c21 -40 46 -72 65 -84 31 -18 44 -22 130
-36 l39 -6 -50 -53 c-64 -68 -78 -112 -63 -197 6 -34 9 -64 7 -66 -2 -2 -32
10 -67 27 -80 38 -118 38 -198 0 -35 -17 -65 -29 -67 -27 -2 2 1 30 7 64 15
84 1 129 -61 197 l-50 54 27 5 c16 3 46 9 68 12 61 10 101 43 138 112 17 35
34 63 37 63 3 0 20 -29 38 -65z"
                />
                <path
                  d="M2805 4528 c-49 -17 -74 -48 -122 -143 -25 -49 -47 -91 -48 -93 -2
-2 -46 -10 -100 -18 -53 -8 -109 -21 -125 -29 -68 -35 -105 -125 -81 -197 6
-19 44 -67 90 -112 l78 -78 -13 -71 c-30 -154 -12 -225 64 -270 57 -33 115
-26 222 28 l93 47 91 -46 c126 -63 180 -65 244 -10 61 52 69 88 48 220 l-18
107 61 54 c107 96 135 176 91 262 -26 52 -71 78 -155 90 -147 23 -129 11 -185
119 -34 67 -60 104 -83 119 -39 27 -110 37 -152 21z m85 -319 c32 -68 86 -115
141 -123 24 -4 56 -9 72 -12 l27 -6 -49 -54 c-61 -67 -73 -102 -60 -191 5 -36
7 -68 5 -70 -2 -2 -31 9 -65 26 -77 39 -118 39 -200 1 -35 -17 -65 -29 -66
-28 -2 2 2 31 8 66 14 85 -1 131 -64 198 l-50 52 70 12 c93 16 129 41 166 118
17 34 33 62 36 62 3 0 16 -23 29 -51z"
                />
                <path
                  d="M4064 4521 c-47 -22 -67 -47 -119 -151 -21 -41 -39 -76 -40 -78 -2
-2 -46 -10 -100 -18 -53 -8 -109 -21 -125 -29 -64 -33 -99 -115 -79 -187 8
-32 31 -63 89 -123 l78 -80 -15 -85 c-25 -143 -5 -218 70 -256 58 -30 113 -23
218 31 l96 48 78 -42 c129 -69 205 -73 266 -12 47 47 55 97 36 214 l-16 102
74 76 c82 84 95 108 95 178 0 91 -56 143 -175 160 -147 23 -129 11 -186 121
-56 110 -85 136 -160 145 -31 4 -56 0 -85 -14z m149 -388 c24 -27 83 -49 152
-58 l50 -7 -41 -37 c-80 -71 -98 -130 -75 -248 l8 -41 -67 34 c-88 45 -128 45
-215 -1 -37 -19 -65 -29 -62 -21 11 29 19 128 12 162 -4 26 -24 55 -62 95
l-55 58 49 6 c114 15 160 49 208 154 l19 43 29 -59 c16 -32 38 -68 50 -80z"
                />
                <path
                  d="M1375 3036 c-28 -29 -40 -66 -31 -102 25 -100 177 -100 202 0 9 36
-3 73 -31 102 -18 18 -35 24 -70 24 -35 0 -52 -6 -70 -24z"
                />
              </g>
            </svg>
          )}
          <span className="quote-icon absolute top-0 left-0 w-10 h-10 grid place-items-center rounded-full translate-y-1/4 text-white-default bg-blue-dark border-2 border-yellow-default">
            <FaQuoteRight />
          </span>
        </div>
        <h4 className="mb-1 text-blue-light text-xl capitalize">{name}</h4>
        <p className="mb-2 uppercase text-sm text-yellow-default">{stars}</p>
        <p className="mb-3">{text}</p>
        <div className="button-container">
          <button
            className="text-xl bg-transparent my-2 transition cursor-pointer"
            onClick={prevPerson}
          >
            <FaChevronLeft />
          </button>
          <button
            className="text-xl bg-transparent my-2 transition cursor-pointer"
            onClick={nextPerson}
          >
            <FaChevronRight />
          </button>
        </div>
        <button
          className="text-white-default group uppercase border w-fit h-12 my-6 pl-6 text-left items-center justify-center bg-blue-light flex hover:shadow-md hover:shadow-blue-dark overflow-hidden relative mx-auto"
          onClick={getRandomPerson}
        >
          <span className="w-56">Get Random Review</span>

          <span className="bg-yellow-default h-full flex items-center justify-center w-14 absolute skew-x-12 -right-2 border-l-2">
            <svg
              className="w-6 h-6 group-hover:-translate-x-4 duration-700"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
              />
            </svg>
          </span>
        </button>
      </article> */}
    </div>
  )
}

export default ReviewSection
