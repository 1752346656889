import OurServices from "./InnerSection/OurServices"
import WhyUs from "./InnerSection/WhyUs"
import React, { useEffect } from "react"
import Navbar from "./layout/Nav/Navbar"
import HomeSection from "./InnerSection/HomeSection"
import AboutSection from "./InnerSection/AboutSection"
import ContactUs from "./InnerSection/ContactUs/ContactUs"
import Footer from "./layout/footer/Footer"
import { useNavigate } from "react-router-dom"
import ReviewSection from "./InnerSection/Reviews/ReviewSection"
import PriceSection from "./InnerSection/PriceSec/PriceSection"
import UpdateSection from "./InnerSection/UpdateSec/UpdateSection"

const MainIndex = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("#home")
  }, [])

  return (
    <div className="md:w-full text-white-default scroll-smooth">
      <div className="w-full fixed border-b-2 rounded-b-2xl md:py-6 py-5 md:px-9 px-3 backdrop-blur-md z-20 bg-blue-light bg-opacity-25">
        <Navbar />
      </div>
      <div className="w-full overflow-x-hidden">
        <HomeSection />
        <AboutSection />
        <OurServices />
        <WhyUs />
        <UpdateSection />
        <ReviewSection />
        <PriceSection />
      </div>
      <ContactUs />
      <Footer />
    </div>
  )
}

export default MainIndex
