export const mainMenu = [
  {
    name: "Home",
    href: "#home",
    route: "/",
  },
  {
    name: "About Us",
    href: "#aboutUs",
    route: "/aboutUs",
  },
  {
    name: "Services",
    href: "#services",
    route: "/services",
  },
  {
    name: "Why Us",
    href: "#whyUs",
    route: "/company",
  },
  {
    name: "Latest News",
    href: "#updates",
    route: "/updates",
  },
  {
    name: "Pricing",
    href: "#pricing",
    route: "/pricing",
  },
  // {
  //   name: "Blog",
  //   route: "/blog",
  // },
  // {
  //   name: "FAQ",
  //   route: "/faq",
  // //   subMenu: [
  // //     {
  // //       name: "name",
  // //       route: "",
  // //     },
  // //   ],
  // },
]
