import React from "react"
import truck1 from "../../asserts/truck1.jpeg"
import truck2 from "../../asserts/truck2.jpeg"

const AboutSection = () => {
  return (
    <>
      <div
        className="text-black bg-white-default h-[50px] flex justify-between w-full"
        id="aboutUs"
      >
        <div className="w-1/5 h-21 bg-white-default skew-x-[45deg] -mt-12"></div>
        <div className="w-1/5 h-21 bg-white-default -skew-x-[45deg] -mt-12"></div>
      </div>

      {/* Left sec */}
      <div className="text-black bg-white-default flex md:flex-row flex-col w-full justify-center items-center my-24">
        <div className="md:w-[40%] w-full relative h-[400px]">
          <img
            src={truck2}
            alt=""
            className="absolute w-72 md:left-32 left-[30%] md:-top-7 -top-20 p-1 border-4"
          />
          <div>
            <img
              src={truck1}
              alt=""
              className="absolute w-72 md:-left-24 md:bottom-0 bottom-9 p-1 border-4"
            />
          </div>

          <div className="absolute w-40 h-40 bg-blue-light text-white-default bottom-6 md:left-[17rem] left-[60%] border-4 flex flex-col justify-center items-center text-center">
            <span className="font-bold text-4xl">14+</span>
            <span className="mt-2">
              Years of <br /> experience
            </span>
          </div>
        </div>

        {/* right sec */}
        <div className="md:w-2/6 space-y-12 flex flex-col justify-center md:mt-0 mt-10 md:ml-16 mx-5">
          <div className="capitalize text-yellow-default flex items-center">
            <hr className="w-10 mt-1 mr-2 border-2 rounded-full border-yellow-default" />
            <span>about us</span>
          </div>
          <div className="md:space-y-3 ">
            <p>
              Millwoods Furnace Cleaning Ltd: Your Trusted HVAC and Duct
              Cleaning Experts.
            </p>
            <p>
              At Millwoods Furnace Cleaning Ltd, we take pride in providing
              top-notch services for both residential and commercial clients.
              Our friendly and dedicated team ensures that your HVAC system and
              ductwork are transformed from dirty and germ-laden to clean,
              fresh, and healthy. As a locally owned family business since 2010,
              we understand the unique needs of our neighbors in Edmonton and
              beyond. Whether it’s furnace cleaning, duct cleaning, or high
              dusting, we’ve got you covered. Choose Millwoods Furnace Cleaning
              Ltd for a cleaner, healthier environment. Contact us today! 😊
            </p>
          </div>
          <a href="#whyUs">
            <button className="text-white-default group md:mt-20 uppercase border w-fit h-12 pl-6 text-left items-center justify-center bg-blue-light flex hover:shadow-md hover:shadow-blue-dark overflow-hidden relative">
              <span className="w-48">More About Us</span>
              <span className="bg-yellow-default h-full flex items-center justify-center w-14 absolute skew-x-12 -right-2 border-l-2">
                <svg
                  className="w-6 h-6 group-hover:-translate-x-4 duration-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                  />
                </svg>
              </span>
            </button>
          </a>
        </div>
      </div>
    </>
  )
}

export default AboutSection
