const reviews = [
  {
    // id: 0,
    name: "Manna",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjXuhEaB29UrfZRXwelpc9oyU8qM6gxuYE1GZE7E0hHnrQI=w60-h60-p-rp-mo-br100",
    text: "Exceptional service! Mill woods Furnace Ltd. went above and beyond to ensure our ducts were immaculately clean. The team was prompt, courteous, and left no mess behind. Our home feels fresher, and we can breathe easier. Five stars all the way!",
  },
  {
    // id: 1,
    name: "Preeti Sethi",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjW8AIcntmNubOjLw31owZtNcYf3O2_4goXCrprcyAgcH20X=w360-h360-p-rp-mo-br100",
    text: "In Service industry it is rare now a days to get value of service you are trying to avail. Many times it happens even if you are paying but you don't get the quality of service you are paying for. Here I guess with Millwoods furnace cleaning Ltd. its a different story. They are really awesome at their job. They super exceeded my expectations and I am thankful to that person also, who recommended this company to me. The team is punctual, dependable, thorough at their job, service quality is so reliable. You get complete value of what you have paid. Keep it up ! And Thank you.",
  },
  {
    // id: 2,
    name: "charito",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocIjB7YV92-13CLsmZjxTuaEAt1uMdIkagUfWak-nHrs=w360-h360-p-rp-mo-br100",
    text: "These guys did an amazing job . Very professional and they came to my place on time. Has good values and I am looking forward to have them clean my furnace  again next time. They even clean my dryer as well and now I can see a lot difference. The price is very affordable. They have all the qualities listed above.",
  },
  {
    // id: 3,
    name: "Beth Kuban",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjW7k-N3g_zXWME5DvRMUdnJV61yZHw-PK0IvlGauyav2Vg=w360-h360-p-rp-mo-br100",
    text: "Millwoods Furnace Cleaning responded promptly to my inquiry emails and had appointments the following week I could take advantage of. The team was very efficient and respectful of my home and all my contained pets. The guys even helped me get my bed frame back in place! They also walked me through all issues discovered and provided me with easy solutions and all information necessary to correct things. Very happy with their service and I would recommend and book with Millwoods Furnace Cleaning again!",
  },
  {
    // id: 5,
    name: "Cynthia",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocKozSUI8IMnSPT3Sb3oYW09jU-gwVaWhwZsgNYqyv3n=w360-h360-p-rp-mo-br100",
    text: "What an amazing job they did for us. They were friendly and very professional. They were very honest and told us what needed to be done and what did not.  They showed the before and after pictures.  Our furnace is even quieter since the clean.  I am so thankful we choose them.  I would recommend Millwoods Furnace Cleaning to anyone.  I will choose them again!",
  },
  {
    // id: 6,
    name: "samandeep kaur",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjWC9kK_4NsDL2trnDGmS3dcRw6xIwkALqEhfem5yg8nxEU=w360-h360-p-rp-mo-ba2-br100",
    text: "Guri and his team did an amazing job cleaning our furnance! He is very knowledgeable, polite and humble person. Very professional service rendered by them. Recommended us to change few things. I would highly recommend them to everyone! We will always get our furance cleaning done from them.",
  },
  {
    // id: 7,
    name: "Terry McGee",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocL9Kiyif_r_I6_-3n7lpmqODTTNJK9bfhm6Fca_FChT=w360-h360-p-rp-mo-br100",
    text: "We had Millwoods Furnace Cleaning come in and do a complete duct and furnace cleaning. The stuff these guys found is crazy. It had been a while since our last cleaning , and wow what a difference. Dry vent had quite a bit of stuff in it . The dryer now runs like new . I would recommend these guys for your next duct work cleaning. Thanks again Terry and Theresa McGee",
  },
  {
    // id: 8,
    name: "RASHPAL SEHMBY",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjVWMNThi4kzqszJxZtFIT6J4NjFdKg8tXrQ_yOLDSx1zsA=w360-h360-p-rp-mo-br100",
    text: "We recently had our ducts cleaned by Guri and his team from Millwoods  Furnace Cleaning. They did a great job with added advice. I recommend them to anyone looking to get their ducts cleaned before the winter, when the Furnace is used the most.",
  },
  {
    // id: 9,
    name: "Puneet gill",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocK0ZOuCSh-aKXSEgsNrbmYRnOMpIgMaZBDJoYr0IqrE=w360-h360-p-rp-mo-br100",
    text: "This company is amazing and so professional.. Excellent communication with us all along the way. We would highly recommend them and also will be using them in the future! Incredible customer service and workmanship! Thank you.",
  },
  {
    // id: 10,
    name: "aravind kr",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjU22jzMfimGPz2hYTY8igQXxyofxjcyHAltJo111x2XIpw=w360-h360-p-rp-mo-ba3-br100",
    text: "Very professional and friendly. I'll definitely call them next time. Great service guys. Thank you!!!",
  },
  {
    // id: 11,
    name: "Tanya",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjV9Dd-rQoZRtGiG4DSUvlAu0wGKESlmf9bpKO-8330uBLk=w360-h360-p-rp-mo-ba2-br100",
    text: "Work was superb, all were very knowledgeable. Also advised on things that I was not aware. I would definitely recommend to family and friends.",
  },
  {
    // id: 12,
    name: "SHEILA MORAH",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjVJjYE80Ow9uTlq-bKS7ovS8LIkTOzjxo5chZSKsbTxz5s=w360-h360-p-rp-mo-br100",
    text: "Very pleased to have them clean our furnaces. Raoul was very kind, attentive and quick. Everything was done smoothly. If you are looking for someone to do the furnaces, call them as you wont be disappointed :)",
  },
  {
    // id: 13,
    name: "Kim Helm",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjXe1Iztp3NBXOckLNQ6qbBafWFq8R1_C80C_Lm1R6Cpbx4=w360-h360-p-rp-mo-br100",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value Incredibly professional, honest and thoughtful of small child in the house. Affordable prices for perfection ! Will definitely recommend to family and friends! Thank you! See you in 2024!",
  },
  {
    // id: 14,
    name: "Marie Bonsol",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjUzpBe7fM9grvR1ugcHtIIurgbRkNrZoiRO7PIBr_zuqL4=w360-h360-p-rp-mo-ba2-br100",
    text: "We had our duct and vent cleaning today done by Rahul and Raj. These two men are very professional, friendly, kind, and knowledgeable about their job. It's amazing to see that so much dust and dirt has been taken out of our ducts and vents. I cleaned my house regularly and vacuum once/ twice weekly using my water seal vacuum because I have a son with asthma and allergies. I feel so relieved that all those dust and dirt are professionally cleaned by Rahul and Raj! I am so happy as well that my dryer lint area has been cleaned! I've been thinking how on earth can I remove those lints stucked deep down inside...i highly recommend this company. Will definitely call them again!",
  },
  {
    // id: 15,
    name: "Tom Welling",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjUevrc2a7w5JJYlr58HQSSUkvPVwKHRtRA73SazkBFkGA=w360-h360-p-rp-mo-br100",
    text: "These guys were very nice. They took their time and did a very good job. Would definitely recommend!",
  },
  {
    name: "Ram Prasad",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Very professional, explained everything well and did a good service. The owner Guri is very knowledgeable and unlike some other companies, he did not try to sell anything extra beyond what we requested. We will definitely use their services again and recommend them.",
  },
  {
    name: "S Pathak",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "The gentlemen Guri and his associate are very knowledgeable and professional in their work. Service is very reasonably priced. We'll use Millwood Furnace cleaning services in future as well!",
  },
  {
    name: "Jagadeesh Samuel",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Millwood furnace cleaning, they did really very good job compare to the other company and I recommend after seeing the job. Thank you Jagadeesh.",
  },
  {
    name: "Lakhwinder Dhillon",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Excellent customer service and genuine advice was provided by Guri during the job. Highly recommended if you want to get Furnace & Duct cleaning.",
  },
  {
    // id: 16,
    name: "Jessica Stevens",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjUKRqfsroHxb9rGAxDgROz00T5GwUJ0CHIjZBOlHxRFoj7Q=w360-h360-p-rp-mo-ba3-br100",
    text: "Really quick response in getting an appointment and a very thorough job with ducts, furnace and air conditioner cleaning.",
  },
  {
    name: "Heiko Duchale",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nVery professional and very Thorough. I highly recommend this company and will continue using them in the future. Very polite and communicative. Happy to have them in our home. Thanks again to Guri and his crew! Hands down the best.\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Mittal Edge",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nBest furnace cleaning and maintenance services in the town. The owner Guri is very knowledgeable and professional. He came right on time and cleaned our furnace thoroughly. Even when we had a problem with heating system, he fixed it in …More\n",
  },
  {
    name: "inderpal singh",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Good service and very helpful and professional. Definitely call them next time.",
  },
  {
    name: "JerryTheDuck Ling",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Value\nThey did the very good job beyond my expectations and highly recommended this company.\nServices: HVAC system maintenance, Repair HVAC, HVAC duct & vent cleaning",
  },
  {
    name: "R Singh",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism\nWe had a great experience with Millwoods Furnace Cleaning having our ducts and vents cleaned. They were very professional and friendly. They were very polite and informative of how I should maintain my furnace duct cleaning & water tank. …More\n",
  },
  {
    name: "Adnan Khalid",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Quality, Responsiveness, Value\nI got service from Millwood Furnace Cleaning guys. They are so professional and very helpful. They have the best rate in town. I have more than 20 vents in house along with …More\n",
  },
  {
    name: "Harry Dhaliwal",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Very Professional highly recommended\nExplained everything very well thank you for your service 👍👍",
  },
  {
    name: "Hs Baring",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Quality\nThey are really good and we are satisfied with service 👍",
  },
  {
    name: "Gurpal Rahul",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Millwood Furnace cleaning team is very professional. Reasonable price, Balli is very knowledgeable about his job, he took some pictures before and after cleaning and show me the difference. I'm very happy with his service. Thanks for your great job 👍 highly recommended.",
  },
  {
    name: "sandeep judge",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Quality, Value\nMillwoods Furnace Cleaning are amazing with Furnance Maintenance and Duct Cleaning.. The owner Guri gives very good recommendation as to how the condition was and makes sure the job is done proper. Thank You.Will surely recommend you guys\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Arun Anand",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism\nVery pleased with Millwoods Furnace Cleaning. Just wanted to take the time to specifically thank Guri for doing an amazing furnace maintenance and duct cleaning job. He was exceptionally knowledgeable, professional and very personable. Highly recommend Guri :)",
  },
  {
    name: "Jaideep Sandhu",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Value\nVery punctual and Excellent service. Also very knowledgeable staff... 👍",
  },
  {
    name: "KALPESH PATEL",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Very professional and reliable work done, I would definitely recommend them among my friend and families",
  },
  {
    name: "Ailyn dela Cruz",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nThey arrived half an hour early and really took time to clean our furnace, ducts, vents and dryer. Great service at a reasonable price. This company is highly recommended.\nServices: Heating system maintenance, HVAC duct & vent cleaning",
  },
  {
    name: "Connie Babcook",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Quality\nVery professional and friendly. First company that actually cleaned my ducts and vents properly. I very happy with the results. They are also reasonably priced!!!\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Harnoor chahal",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism\nVery professional and well behaved person\nService: Heating system repair",
  },
  {
    name: "Janet Grams",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nRaoul and his staff were very friendly and extremely efficient. They cleaned the furnace, all the vents, HVAC unit, and dryer. I appreciated that Raoul could show me photos of the dirt / dust accumulated vents and then once cleaned what …More",
  },
  {
    name: "Krishna Mahajan",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "We got furnace cleaned for our house. Service was quick but detailed and reps were respectful. Will recommend.\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Pradeep N",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism\nThey cleaned our furnace and vents very well. They took time and done there job on time. Highly recommended this guys.\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Tanya Nielsen",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Quality\nThey were super friendly, went above & beyond taking construction garbage out of our vents previous cleaners had just left. Definitely recommend them!\nService: HVAC duct & vent cleaning",
  },
  {
    name: "lucky jassal",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Very helpful guys called them at 9pm and they showed up.in an hours time. My daughter thrown the phone in the air vent and they recovered it in one piece. Good service.",
  },
  {
    name: "Raji Ramanathan",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "They are Great ! Excellent service ! Reasonable price. I would recommend to anyone I know. Awesome !\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Prabhjot Kaur",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Value\nReally good service with reasonable prices. They were right on time for service. I would call them next time and recommend them to anyone who need good professional service. Overall I had good experience with them.\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Rahul Dalal",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Excellent Service - Very Good Staff. Came on Right Time - Gave some really nice tips for House Cleaning. Very Punctual with no rush. Satisfied with Service . Five Stars\nServices: HVAC duct & vent repair, HVAC duct & vent cleaning",
  },
  {
    name: "Sandra Snell",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nProfessional, friendly, and informative. They were efficient and affordable, we were very happy with the service and will use Millwoods Furnace Cleaning again.\nServices: Heating system maintenance, HVAC duct & vent cleaning",
  },
  {
    name: "Sumit",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nAwesome service. They throughly cleaned all vents. Will definitely use their service again in the future.\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Syed Ahmar",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism\nVery professional, good quality service with cheapest price in the market. Very very honest technicians came to my house. I have lost $500 in my house one of the technician found my money and give it to me.",
  },
  {
    name: "AP",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Value\nVery professional, highly recommended, arrived on time , much better than other furnace cleaning companies.\nServices: HVAC duct & vent installation, HVAC duct & vent cleaning",
  },
  {
    name: "jasmin suarez",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Responsiveness, Value\nGreat service for a pretty reasonable price! These guys are now my go-to people when it comes to taking care of our vents and furnace 👍🏻\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Wendy ~",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nWe just had our furnace and ducts cleaned with millwoods furnace and duct cleaners and they did a fantastic job. We highly recommend them.\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Sylvester Nirikana",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nThey explained to me the whole mechanism of functioning furnace\nServices: HVAC system maintenance, HVAC duct & vent installation, HVAC duct & vent cleaning",
  },
  {
    name: "Pankaj Dua",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nVery knowledgeable staff and they cleaned all ducts properly. Very reasonable charges.",
  },
  {
    name: "Bob Shamrock",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Quality\nVery responsible. Went above and beyond! I'm really satisfied with this service :) Rahul is the dude!\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Samira Yehia",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism\nThey did a great job I highly recommend their services",
  },
  {
    name: "Vinod Kamboj",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nGood prices, excellent service, professional and punctual. What else you want..... Good job guys. Keep it up👍🏻\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Raymond Miller",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nThese guys are awesome. Kind fast excellent job even with pictures before and after. Thank you so much👍🏻\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Ductsedmonton Alberta",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism\nI called them for the furnace cleaning\n... These guys are very professional and did a very good job\nBest furnace cleaning company of Edmonton\nService: Ductless heating & A/C services",
  },
  {
    name: "Jessica Stevens",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Really quick response in getting an appointment and a very thorough job with ducts, furnace, and air conditioner cleaning.",
  },
  {
    name: "diljot singh",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nQuick service and nice job\nService: HVAC system maintenance",
  },
  {
    name: "Tabina Asif",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism\nGreat service, did very detailed and thorough cleaning\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Richard Poon",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Great work and efficient",
  },
  {
    name: "Jagdish Masiwal",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Professional, reasonable, friendly service .will be using them for future services. Best service in affordable price.",
  },
  {
    name: "Kiran Lakhi",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "They did good job properly clean, Handle their job professionally and reasonable price.... I like it.👍👍👍👍👍👍",
  },
  {
    name: "bikila aga",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nThese guys are real professionals. They are very detail-oriented.\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Internet Social Trend",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Cheapest cleaning company and they will make sure you're satisfied with their service.",
  },
  {
    name: "Sunny Sandhu",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Quality\nProfessional, reasonable, friendly service .will be using them for future services",
  },
  {
    name: "Flower Flowers",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Very professional and friendly, highly recommended!\nServices: HVAC duct & vent installation, HVAC duct & vent cleaning",
  },
  {
    name: "gopi waraich",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Excellent service 👍👍",
  },
  {
    name: "Kudrat Bamb",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Best service in Edmonton, professional in their work.... highly recommended",
  },
  {
    name: "malik Hassan",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "They provide the best quality service in cheap rates",
  },
  {
    name: "Birinder Grewal",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nTheir work is really professional.",
  },
  {
    name: "Jeet Sekhon",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Reasonable price, and excellent service, good job.",
  },
  {
    name: "Tejinder Kaler",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nHad a great experience highly recommended\nServices: HVAC duct & vent repair, HVAC duct & vent cleaning",
  },
  {
    name: "Jass Kaler",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nHighly recommended great work\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Jaskiran Cheema",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Great service and affordable price",
  },
  {
    name: "M A",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Fair pricing, awesome service",
  },
  {
    name: "Pardeep Bapla",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nHighly recommend these guys\nService: HVAC duct & vent cleaning",
  },
  {
    name: "miretu keno",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Great job guys\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Sukhdeep Singh",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Best service ever 👍👍",
  },
  {
    name: "My Little POGI",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism\nHighly recommended.\nService: HVAC duct & vent cleaning",
  },
  {
    name: "Gurkirat Dhaliwal",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value\nExcellent job\nService: HVAC duct & vent cleaning",
  },
  {
    // id: 17,
    name: "Gurdeep Kooner",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocIOR-Nk1K-woWd7X89uyycJln1MNtv0ZWA4HR1Zb15d=w60-h60-p-rp-mo-br100",
    text: "Services: Repair HVAC, Heating system maintenance, HVAC duct & vent cleaning Positive: Professionalism, Quality, Responsiveness",
  },
  {
    // id: 17,
    name: "Ma Jessica Junos",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocKawFexw1ZMnns1xGysQJ1MoxXpHATnQVo6r00BQA1e=w60-h60-p-rp-mo-br100",
    text: "Positive: Professionalism, Quality, Responsiveness, Value",
  },
  {
    // id: 17,
    name: "Dale Vardon",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocLZBsO7WtJPB-lJAWxsXiFZ7yDBvvtB3YZX7vQ3gD3M=w60-h60-p-rp-mo-br100",
    text: "Service: HVAC duct & vent cleaning Positive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    // id: 17,
    name: "Maninder Mangat",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjXPvf6pFoRC8erc9sJXiDg0OIux8QzKY24shPiQkI53vb8=w60-h60-p-rp-mo-br100",
    text: "Service: HVAC duct & vent cleaning Positive: Quality",
  },
  {
    // id: 17,
    name: "Arslan Ahmad",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjXJKjZbkndRYd0-NNVAuiO-jIAMdUz259ZybBrGM7hhqCk=w60-h60-p-rp-mo-br100",
    text: "Service: HVAC duct & vent cleaning Positive: Professionalism",
  },
  {
    // id: 17,
    name: "Simranpal Khattra",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocKoN7ddwDdbaTzSFW9812SqEQMacMicnCqdH_NnLb2P=w60-h60-p-rp-mo-br100",
    text: "Services: HVAC duct & vent repair, Repair HVAC, HVAC duct & vent cleaning Positive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    // id: 17,
    name: "Sally Diongzon",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocLV94zBpUc4kfOXfZMSMCnNLS5bNvpkBh--WsDN77Eb=w60-h60-p-rp-mo-br100",
    text: "Service: HVAC system maintenance Positive: Professionalism, Punctuality, Quality, Responsiveness",
  },
  {
    // id: 17,
    name: "Gurleen Grewal",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjWdqa-P2A5YUc0PaWTjbNwRa81egeX3Qa2Sqs0lhyZuUZI=w60-h60-p-rp-mo-br100",
    text: "Services: Heating system repair, HVAC duct & vent repair, HVAC duct & vent cleaning Positive: Professionalism",
  },
  {
    // id: 17,
    name: "Gurleen Grewal",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjWdqa-P2A5YUc0PaWTjbNwRa81egeX3Qa2Sqs0lhyZuUZI=w60-h60-p-rp-mo-br100",
    text: "Services: Heating system repair, HVAC duct & vent repair, HVAC duct & vent cleaning Positive: Professionalism",
  },
  {
    // id: 17,
    name: "Bhupinder Pal Singh Deol",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a-/ALV-UjXkxTHU_ybk-rSnK4su8aSg_HlnWQzkyiO8Kf_YqRLyKG8=w60-h60-p-rp-mo-ba3-br100",
    text: "Service: HVAC duct & vent cleaning Positive: Professionalism",
  },
  {
    // id: 17,
    name: "mark anthony albano",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocIgV4Zj3uJwrYswEqDbEWG3NuRzj20Eig6YAF57ybrm=w60-h60-p-rp-mo-br100",
    text: "Service: HVAC duct & vent cleaning Positive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    // id: 17,
    name: "Amandeep Virk",
    stars: "⭐⭐⭐⭐⭐",
    image:
      "https://lh3.googleusercontent.com/a/ACg8ocLsy30WRcmQmrfwQsEAczaVtKBzPOAASMvF7QJZD3mN-6U=w60-h60-p-rp-mo-ba3-br100",
    text: "Service: HVAC duct & vent cleaning Positive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "Nishita Nayak",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Quality",
  },
  {
    name: "Rizwan Talk",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Quality",
  },
  {
    name: "Vikas Bangar",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "Harsimrat Kaur",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "Scott Fulford",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Professionalism, Quality, Value",
  },
  {
    name: "Ricky pity her",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Quality",
  },
  {
    name: "Get Visavadiya",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Professionalism, Responsiveness, Value",
  },
  {
    name: "Manpreet Khaira",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "Balwinder Singh",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism",
  },
  {
    name: "Shane Anthony",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Services: HVAC system maintenance, A/C system maintenance, A/C system repair, HVAC duct & vent repair, HVAC duct & vent cleaning\nPositive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "harmandeep brar",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "Sukhdeep Sharma",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Services: Heating system repair, HVAC duct & vent repair, HVAC duct & vent cleaning",
  },
  {
    name: "Your Yours",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Services: HVAC duct & vent repair, HVAC duct & vent cleaning\nPositive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "Caesar Zmyslowski",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Thanks sir",
  },
  {
    name: "Harman Pandher",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "ALI Haider",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning",
  },
  {
    name: "Manjinder Singh",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Quality, Value",
  },
  {
    name: "Sidath Gunasekara",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Professionalism, Quality, Value",
  },
  {
    name: "Irfan Butt",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism",
  },
  {
    name: "Job Samuel",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning",
  },
  {
    name: "DILEEP NARAN",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "Houda Tayba",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Service: HVAC duct & vent cleaning\nPositive: Professionalism, Quality, Responsiveness, Value",
  },
  {
    name: "Starlight Rena Canada",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Best srvice and amazing job highly recommend",
  },
  {
    name: "Gurpreet Singh Buall",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Professionalism, Punctuality, Quality, Responsiveness, Value",
  },
  {
    name: "Hardeep Sidhu",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Best srvice and amazing job highly recommend",
  },
  {
    name: "Sahib Khurana",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Best srvice and amazing job highly recommend",
  },
  {
    name: "nelson dinglasan",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Services: HVAC system maintenance, HVAC duct & vent repair, HVAC duct & vent cleaning\nPositive: Professionalism",
  },
  {
    name: "Syed Jawad",
    stars: "⭐⭐⭐⭐⭐",
    image: "",
    text: "Positive: Quality, Professionalism, Punctuality,",
  },
]

export default reviews
