import React, { useState } from "react"
import { mainMenu } from "./NavConfig"
import { useLocation } from "react-router"
// import NavDrawer from "./NavDrawer"

const Navbar = () => {
  let location = useLocation()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <div className="flex justify-between w-full items-center">
        <div className="md:block hidden h-full cursor-pointer">
          <img
            src="https://readme-typing-svg.herokuapp.com?font=Protest+Guerrilla&size=28&duration=5000&pause=600&color=F7F7F7&center=true&vCenter=true&random=false&width=480&height=40&lines=Millwoods+Furnace+Cleaning"
            alt="Typing SVG"
          />
        </div>
        <div className="block md:hidden h-full cursor-pointer">
          <img
            src="https://readme-typing-svg.herokuapp.com?font=Protest+Guerrilla&size=24&duration=2000&pause=600&color=F7F7F7&center=true&vCenter=true&random=false&width=120&height=24&lines=MFC"
            alt="Typing SVG"
          />
        </div>

        {/* Full menu */}
        <div className="hidden md:flex justify-between items-center">
          {mainMenu.map((data, key) => {
            return (
              <>
                <div
                  className="relative w-24 flex justify-center py-1"
                  key={"adminMenu" + key}
                >
                  <button className="hover:font-semibold">
                    <a href={data.href}>{data.name}</a>
                  </button>
                  {data.href === location.hash && (
                    <div className="absolute -bottom-1 -z-10">
                      <div className="h-2 w-2 rounded-full bg-white-default flex shadow-xl shadow-white-default"></div>
                    </div>
                  )}
                </div>
              </>
            )
          })}
          <a href="#contactUs">
            <button className="uppercase bg-yellow-default lg:py-2 lg:px-3 ml-2 font font-semibold">
              request an estimation
            </button>
          </a>
        </div>

        {/* Burger Menu */}
        <div className="md:hidden">
          {/* <NavDrawer /> */}

          <div
            onClick={() => {
              handleOpen()
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>

          <div
            className={` absolute bg-white-default -bottom-[95vh] top-0 right-0 left-4 duration-700 ${
              open ? "translate-x-0" : "translate-x-full"
            } `}
          >
            <div
              className="text-black flex justify-end mr-5 mt-5"
              onClick={() => {
                handleOpen()
              }}
            >
              <svg
                className="w-10 h-10"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </div>
            <div className=" h-full flex flex-col justify-center -mt-8">
              {mainMenu.map((data, key) => {
                return (
                  <div
                    key={key}
                    className="flex justify-center my-5 text-white-default font-medium"
                  >
                    <a href={data.href}>
                      <button
                        className="bg-blue-light py-2 w-48 rounded-lg border-2 border-yellow-default"
                        onClick={() => {
                          handleOpen()
                        }}
                      >
                        {data.name}
                      </button>
                    </a>
                  </div>
                )
              })}
              <a href="#contactUs" className="mx-auto my-5">
                <button
                  className="uppercase bg-yellow-default py-2 px-3 font-semibold"
                  onClick={() => {
                    handleOpen()
                  }}
                >
                  request an estimation
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
