import MainIndex from "./components/MainIndex"
import { BrowserRouter as Router } from "react-router-dom"

function App() {
  return (
    <Router>
      <div className="w-full ">
        <MainIndex />
      </div>
    </Router>
  )
}

export default App
