import React from "react"
import hero from "../../asserts/heroImg.png"
import cleanimg from "../../asserts/starswhite.png"
import { useNavigate } from "react-router-dom"

const HomeSection = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="w-full flex justify-center h-[720px] relative" id="home">
        <div className="w-full">
          <img
            src={hero}
            alt="main"
            className="object-cover w-full h-[720px]"
          />
          <div className=" w-full absolute top-0 bg-blue-light h-[720px] bg-opacity-85 flex flex-col justify-center items-center">
            <div className="flex flex-col md:text-4xl text-2xl text-center mt-5">
              <span className="capitalize">revitalize your</span>
              <div className="flex mx-auto pt-2">
                <div className="hidden md:block">
                  <img
                    src="https://readme-typing-svg.herokuapp.com?font=Poppins&weight=700&size=32&duration=3000&pause=900&color=F7F7F7&center=true&vCenter=true&random=false&width=360&lines=Professional+Furnace,;Air+Duct+Cleaning,"
                    alt="Typing SVG"
                  />
                </div>
                <div className="md:hidden block -mt-1">
                  <img
                    src="https://readme-typing-svg.herokuapp.com?font=Poppins&weight=600&size=22&duration=3000&pause=900&color=F7F7F7&center=true&vCenter=true&random=false&width=250&lines=Professional+Furnace,;Air+Duct+Cleaning,"
                    alt="Typing SVG"
                  />
                </div>
                <span>We Clean</span>
              </div>
              <span>with Grace!</span>
              <p className="font-normal text-sm md:w-1/2 w-11/12 mx-auto pt-6">
                Welcome to our website, your one-stop destination for
                professional furnace and duct cleaning services! We understand
                the importance of maintaining a clean and healthy indoor
                environment, which is why we offer comprehensive cleaning
                solutions for your furnace and duct systems.
              </p>
            </div>
            <a href="#services">
              <button className="z-10 text-white-default group md:mt-20 mt-12 uppercase border h-12 px-6 text-left items-center justify-center bg-blue-light flex hover:shadow-md hover:shadow-white-default overflow-hidden relative">
                <span className="w-52">Explore Our Services</span>
                <span className="bg-yellow-default h-full flex items-center justify-center w-14 absolute skew-x-12 -right-2 border-l-2">
                  <svg
                    className="w-6 h-6 group-hover:-translate-x-4 duration-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                    />
                  </svg>
                </span>
              </button>
            </a>
          </div>
        </div>

        {/* stars icons */}
        <div className="absolute top-6 opacity-60 left-12">
          <img src={cleanimg} alt="" className="w-40 object-cover" />
        </div>
        <div className="absolute top-[70%] md:top-[60%] opacity-60 left-[35%]">
          <img src={cleanimg} alt="" className="w-40 object-cover" />
        </div>
        <div className="absolute hidden md:block md:top-[30%] opacity-60 right-[2%]">
          <img src={cleanimg} alt="" className="w-40 object-cover" />
        </div>
      </div>
    </>
  )
}

export default HomeSection
