import React from "react";
import "./PriceSec.css";

const PriceSection = () => {
  // Define pricing data
  const pricingData = [
    { title: "10 Vents and Furnace", price: "$129" },
    { title: "Extra Furnace Unit", price: "$30" },
    { title: "HRV", price: "$30" },
    { title: "Dryer Machine", price: "$30" },
    { title: "AC Compressor", price: "$50" },
  ];

  return (
    <>
      {/* <div className="container mx-auto py-8">
        <h1 className="text-3xl font-semibold mb-8">Pricing</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {pricingData.map((item) => (
            <div
              key={item.title}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-4">{item.title}</h2>
                <p className="text-gray-700 text-lg">{item.price}</p>
              </div>
              <div className="bg-blue-500 py-3 text-white text-center">
                Book Now
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <div style={{ position: "relative" }}>
        <p
          name="anchor"
          style={{ position: "absolute", top: "-90px" }}
          id="pricing"
        >
          
        </p>
      </div>
      <div
        className="flex flex-col justify-center items-center bg-blue-light py-6 space-y-8"
        style={{ boxSizing: "border-box", padding: "6rem 0rem" }}
      >
        <div className="flex space-x-3">
          <hr className="w-14 mt-3 border rounded-full border-white-default" />
          <span className="font-medium">Pricing</span>
          <hr className="w-14 mt-3 border rounded-full border-white-default" />
        </div>
        <div className="my-2">
          <span className="text-4xl">Our Best Pricing</span>
        </div>

        <div className="container">
          {/* <!-- Card 1 --> */}
          <div className="card">
            <div className="card__info">
              <h2 className="uppercase my-2">HRV & Dryer machine</h2>
              <p className="card__price">
                $30 <span className="card__priceSpan">one time</span>
              </p>
            </div>
            <div className="card__content">
              <div className="card__rows">
                <p className="card__row">HRV System Cleaning</p>
                <p className="card__row">Dryer Machine Vent Cleaning</p>
                <p className="card__row">Advanced Cleaning Techniques</p>
                <p className="card__row">Compliance with Standards</p>
                <p className="card__row">Fully Support</p>
              </div>
              <a href="#contactUs" className="m-5">
                <button className="text-white-default group uppercase border w-fit h-12 pl-2 text-left items-center justify-center bg-blue-light flex hover:shadow-md hover:shadow-blue-dark overflow-hidden relative">
                  <span className="w-40">Get quote</span>
                  <span className="bg-yellow-default h-full flex items-center justify-center w-14 absolute skew-x-12 -right-2 border-l-2">
                    <svg
                      className="w-6 h-6 group-hover:-translate-x-4 duration-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </span>
                </button>
              </a>
            </div>
          </div>

          {/* <!-- Card 2 --> */}
          <div className="card">
            <div className="card__info">
              <h2 className="uppercase my-2">AC compressor</h2>
              <p className="card__price">
                $50.00 <span className="card__priceSpan">one time</span>
              </p>
            </div>
            <div className="card__content">
              <div className="card__rows">
                <p className="card__row">Comprehensive Inspection</p>
                <p className="card__row">Condenser Coil Cleaning</p>
                <p className="card__row">Comprehensive Inspection</p>
                <p className="card__row">Condenser Coil Cleaning</p>
                <p className="card__row">Fully Support</p>
              </div>
              <a href="#contactUs" className="m-5">
                <button className="text-white-default group uppercase border w-fit h-12 pl-2 text-left items-center justify-center bg-blue-light flex hover:shadow-md hover:shadow-blue-dark overflow-hidden relative">
                  <span className="w-40">Get quote</span>
                  <span className="bg-yellow-default h-full flex items-center justify-center w-14 absolute skew-x-12 -right-2 border-l-2">
                    <svg
                      className="w-6 h-6 group-hover:-translate-x-4 duration-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </span>
                </button>
              </a>
            </div>
          </div>

          {/* <!-- Card 3 --> */}
          <div className="card">
            <div className="card__info">
              <h2 className="uppercase my-2">10 vents and furnace</h2>
              <p className="card__price">
                $129.00 <span className="card__priceSpan">all</span>
              </p>
            </div>
            <div className="card__content">
              <div className="card__rows">
                <p className="card__row">Thorough Vent and Furnace Cleaning</p>
                <p className="card__row">Improvement in Airflow</p>
                <p className="card__row">Enhanced Energy Efficiency</p>
                <p className="card__row">Personalized Cleaning Solutions</p>
                <p className="card__row">Customer Satisfaction Guarantee</p>
              </div>
              <a href="#contactUs" className="m-5">
                <button className="text-white-default group uppercase border w-fit h-12 pl-2 text-left items-center justify-center bg-blue-light flex hover:shadow-md hover:shadow-blue-dark overflow-hidden relative">
                  <span className="w-40">Get quote</span>
                  <span className="bg-yellow-default h-full flex items-center justify-center w-14 absolute skew-x-12 -right-2 border-l-2">
                    <svg
                      className="w-6 h-6 group-hover:-translate-x-4 duration-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceSection;
